import validate from "/codebuild/output/src3345967594/src/node_modules/nuxt/dist/pages/runtime/validate.js";
import donate_45global from "/codebuild/output/src3345967594/src/middleware/donate.global.ts";
import user_45global from "/codebuild/output/src3345967594/src/middleware/user.global.ts";
export const globalMiddleware = [
  validate,
  donate_45global,
  user_45global
]
export const namedMiddleware = {
  admin: () => import("/codebuild/output/src3345967594/src/middleware/admin.ts"),
  auth: () => import("/codebuild/output/src3345967594/src/middleware/auth.ts"),
  otp: () => import("/codebuild/output/src3345967594/src/middleware/otp.ts")
}