import { default as indexnrLdhR2u1eMeta } from "/codebuild/output/src3345967594/src/pages/[slug]/index.vue?macro=true";
import { default as accept_45invite1UNG5k3CnTMeta } from "/codebuild/output/src3345967594/src/pages/accept-invite.vue?macro=true";
import { default as extra_45purchaseY9gvMMwv6wMeta } from "/codebuild/output/src3345967594/src/pages/account/extra-purchase.vue?macro=true";
import { default as indexKYHIEtUmeFMeta } from "/codebuild/output/src3345967594/src/pages/account/index.vue?macro=true";
import { default as settings74wP0IZJGZMeta } from "/codebuild/output/src3345967594/src/pages/account/settings.vue?macro=true";
import { default as subscriptionWtWEnBYlIjMeta } from "/codebuild/output/src3345967594/src/pages/account/subscription.vue?macro=true";
import { default as transactionsCJTCb2aCclMeta } from "/codebuild/output/src3345967594/src/pages/account/transactions.vue?macro=true";
import { default as update_45subscriptionV0LJWdvY5lMeta } from "/codebuild/output/src3345967594/src/pages/account/update-subscription.vue?macro=true";
import { default as previewuHXBM2MSLhMeta } from "/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/emails/[id]/preview.vue?macro=true";
import { default as composeoA8Yt2HjnLMeta } from "/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/emails/compose.vue?macro=true";
import { default as index6CWKez5HyvMeta } from "/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/emails/index.vue?macro=true";
import { default as emailsTD3CgGVxkJMeta } from "/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/emails.vue?macro=true";
import { default as editL94Ta43Yl2Meta } from "/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/events/[id]/edit.vue?macro=true";
import { default as registrationsYulb5naUJOMeta } from "/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/events/[id]/registrations.vue?macro=true";
import { default as indexI2gDbb3Mc6Meta } from "/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/events/index.vue?macro=true";
import { default as eventscczQq3wAyPMeta } from "/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/events.vue?macro=true";
import { default as filesOG5q21Hw07Meta } from "/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/files.vue?macro=true";
import { default as galleryxAVmRs3wKrMeta } from "/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/gallery.vue?macro=true";
import { default as group_45detailsVM8mIkseDgMeta } from "/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/group-details.vue?macro=true";
import { default as group_45settingsXnckRt8iaHMeta } from "/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/group-settings.vue?macro=true";
import { default as indexP7FvawDMASMeta } from "/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/index.vue?macro=true";
import { default as membersNqdn0XCma8Meta } from "/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/members.vue?macro=true";
import { default as editUfHdf2EYO6Meta } from "/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/news/[id]/edit.vue?macro=true";
import { default as indexWSXhbcHfCDMeta } from "/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/news/index.vue?macro=true";
import { default as news0Yttnwt8aFMeta } from "/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/news.vue?macro=true";
import { default as _91slug_93NSwKLeu7XuMeta } from "/codebuild/output/src3345967594/src/pages/donate/[slug].vue?macro=true";
import { default as index7MnawIDSgRMeta } from "/codebuild/output/src3345967594/src/pages/donate/index.vue?macro=true";
import { default as email_45unsubscribePv64XAUdNeMeta } from "/codebuild/output/src3345967594/src/pages/email-unsubscribe.vue?macro=true";
import { default as _91slug_93LKAOCj78hVMeta } from "/codebuild/output/src3345967594/src/pages/events/[slug].vue?macro=true";
import { default as indexPirZ1YnUt9Meta } from "/codebuild/output/src3345967594/src/pages/events/index.vue?macro=true";
import { default as forgot_45passwordkFmvwlHx8kMeta } from "/codebuild/output/src3345967594/src/pages/forgot-password.vue?macro=true";
import { default as filesBDghB0pJq6Meta } from "/codebuild/output/src3345967594/src/pages/groups/[slug]/files.vue?macro=true";
import { default as gallery7uRfpOd0FRMeta } from "/codebuild/output/src3345967594/src/pages/groups/[slug]/gallery.vue?macro=true";
import { default as indexyc9SLrs9beMeta } from "/codebuild/output/src3345967594/src/pages/groups/[slug]/index.vue?macro=true";
import { default as shopQXYcZLSrg8Meta } from "/codebuild/output/src3345967594/src/pages/groups/[slug]/shop.vue?macro=true";
import { default as _91slug_93sfPqKzUSiTMeta } from "/codebuild/output/src3345967594/src/pages/groups/[slug].vue?macro=true";
import { default as indexJFIFlRm5iAMeta } from "/codebuild/output/src3345967594/src/pages/groups/index.vue?macro=true";
import { default as indexBJYGVH1j9HMeta } from "/codebuild/output/src3345967594/src/pages/index.vue?macro=true";
import { default as joinfeH4i4F5e8Meta } from "/codebuild/output/src3345967594/src/pages/join.vue?macro=true";
import { default as loginGv32SVZ9wIMeta } from "/codebuild/output/src3345967594/src/pages/login.vue?macro=true";
import { default as confirmation0PyvTkCVeaMeta } from "/codebuild/output/src3345967594/src/pages/membership/confirmation.vue?macro=true";
import { default as indexHkHOp0pkdfMeta } from "/codebuild/output/src3345967594/src/pages/membership/index.vue?macro=true";
import { default as _91slug_93mIbIwfbISzMeta } from "/codebuild/output/src3345967594/src/pages/news/[slug].vue?macro=true";
import { default as indexNw07eotLD8Meta } from "/codebuild/output/src3345967594/src/pages/news/index.vue?macro=true";
import { default as reset_45passwordbuukF1bwazMeta } from "/codebuild/output/src3345967594/src/pages/reset-password.vue?macro=true";
import { default as setup_452facTO1Ff16KjMeta } from "/codebuild/output/src3345967594/src/pages/setup-2fa.vue?macro=true";
import { default as shopjemf4qixYPMeta } from "/codebuild/output/src3345967594/src/pages/shop.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/codebuild/output/src3345967594/src/pages/[slug]/index.vue")
  },
  {
    name: "accept-invite",
    path: "/accept-invite",
    component: () => import("/codebuild/output/src3345967594/src/pages/accept-invite.vue")
  },
  {
    name: "account-extra-purchase",
    path: "/account/extra-purchase",
    meta: extra_45purchaseY9gvMMwv6wMeta || {},
    component: () => import("/codebuild/output/src3345967594/src/pages/account/extra-purchase.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indexKYHIEtUmeFMeta || {},
    component: () => import("/codebuild/output/src3345967594/src/pages/account/index.vue")
  },
  {
    name: "account-settings",
    path: "/account/settings",
    meta: settings74wP0IZJGZMeta || {},
    component: () => import("/codebuild/output/src3345967594/src/pages/account/settings.vue")
  },
  {
    name: "account-subscription",
    path: "/account/subscription",
    meta: subscriptionWtWEnBYlIjMeta || {},
    component: () => import("/codebuild/output/src3345967594/src/pages/account/subscription.vue")
  },
  {
    name: "account-transactions",
    path: "/account/transactions",
    meta: transactionsCJTCb2aCclMeta || {},
    component: () => import("/codebuild/output/src3345967594/src/pages/account/transactions.vue")
  },
  {
    name: "account-update-subscription",
    path: "/account/update-subscription",
    meta: update_45subscriptionV0LJWdvY5lMeta || {},
    component: () => import("/codebuild/output/src3345967594/src/pages/account/update-subscription.vue")
  },
  {
    name: emailsTD3CgGVxkJMeta?.name,
    path: "/admin/groups/:slug()/emails",
    meta: emailsTD3CgGVxkJMeta || {},
    component: () => import("/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/emails.vue"),
    children: [
  {
    name: "admin-groups-slug-emails-id-preview",
    path: ":id()/preview",
    meta: previewuHXBM2MSLhMeta || {},
    component: () => import("/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/emails/[id]/preview.vue")
  },
  {
    name: "admin-groups-slug-emails-compose",
    path: "compose",
    meta: composeoA8Yt2HjnLMeta || {},
    component: () => import("/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/emails/compose.vue")
  },
  {
    name: "admin-groups-slug-emails",
    path: "",
    meta: index6CWKez5HyvMeta || {},
    component: () => import("/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/emails/index.vue")
  }
]
  },
  {
    name: eventscczQq3wAyPMeta?.name,
    path: "/admin/groups/:slug()/events",
    meta: eventscczQq3wAyPMeta || {},
    component: () => import("/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/events.vue"),
    children: [
  {
    name: "admin-groups-slug-events-id-edit",
    path: ":id()/edit",
    meta: editL94Ta43Yl2Meta || {},
    component: () => import("/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/events/[id]/edit.vue")
  },
  {
    name: "admin-groups-slug-events-id-registrations",
    path: ":id()/registrations",
    meta: registrationsYulb5naUJOMeta || {},
    component: () => import("/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/events/[id]/registrations.vue")
  },
  {
    name: "admin-groups-slug-events",
    path: "",
    meta: indexI2gDbb3Mc6Meta || {},
    component: () => import("/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/events/index.vue")
  }
]
  },
  {
    name: "admin-groups-slug-files",
    path: "/admin/groups/:slug()/files",
    meta: filesOG5q21Hw07Meta || {},
    component: () => import("/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/files.vue")
  },
  {
    name: "admin-groups-slug-gallery",
    path: "/admin/groups/:slug()/gallery",
    meta: galleryxAVmRs3wKrMeta || {},
    component: () => import("/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/gallery.vue")
  },
  {
    name: "admin-groups-slug-group-details",
    path: "/admin/groups/:slug()/group-details",
    meta: group_45detailsVM8mIkseDgMeta || {},
    component: () => import("/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/group-details.vue")
  },
  {
    name: "admin-groups-slug-group-settings",
    path: "/admin/groups/:slug()/group-settings",
    meta: group_45settingsXnckRt8iaHMeta || {},
    component: () => import("/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/group-settings.vue")
  },
  {
    name: "admin-groups-slug",
    path: "/admin/groups/:slug()",
    meta: indexP7FvawDMASMeta || {},
    component: () => import("/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/index.vue")
  },
  {
    name: "admin-groups-slug-members",
    path: "/admin/groups/:slug()/members",
    meta: membersNqdn0XCma8Meta || {},
    component: () => import("/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/members.vue")
  },
  {
    name: news0Yttnwt8aFMeta?.name,
    path: "/admin/groups/:slug()/news",
    meta: news0Yttnwt8aFMeta || {},
    component: () => import("/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/news.vue"),
    children: [
  {
    name: "admin-groups-slug-news-id-edit",
    path: ":id()/edit",
    meta: editUfHdf2EYO6Meta || {},
    component: () => import("/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/news/[id]/edit.vue")
  },
  {
    name: "admin-groups-slug-news",
    path: "",
    meta: indexWSXhbcHfCDMeta || {},
    component: () => import("/codebuild/output/src3345967594/src/pages/admin/groups/[slug]/news/index.vue")
  }
]
  },
  {
    name: "donate-slug",
    path: "/donate/:slug()",
    component: () => import("/codebuild/output/src3345967594/src/pages/donate/[slug].vue")
  },
  {
    name: "donate",
    path: "/donate",
    component: () => import("/codebuild/output/src3345967594/src/pages/donate/index.vue")
  },
  {
    name: "email-unsubscribe",
    path: "/email-unsubscribe",
    component: () => import("/codebuild/output/src3345967594/src/pages/email-unsubscribe.vue")
  },
  {
    name: "events-slug",
    path: "/events/:slug()",
    component: () => import("/codebuild/output/src3345967594/src/pages/events/[slug].vue")
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/codebuild/output/src3345967594/src/pages/events/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/codebuild/output/src3345967594/src/pages/forgot-password.vue")
  },
  {
    name: _91slug_93sfPqKzUSiTMeta?.name,
    path: "/groups/:slug()",
    component: () => import("/codebuild/output/src3345967594/src/pages/groups/[slug].vue"),
    children: [
  {
    name: "groups-slug-files",
    path: "files",
    component: () => import("/codebuild/output/src3345967594/src/pages/groups/[slug]/files.vue")
  },
  {
    name: "groups-slug-gallery",
    path: "gallery",
    component: () => import("/codebuild/output/src3345967594/src/pages/groups/[slug]/gallery.vue")
  },
  {
    name: "groups-slug",
    path: "",
    component: () => import("/codebuild/output/src3345967594/src/pages/groups/[slug]/index.vue")
  },
  {
    name: "groups-slug-shop",
    path: "shop",
    component: () => import("/codebuild/output/src3345967594/src/pages/groups/[slug]/shop.vue")
  }
]
  },
  {
    name: "groups",
    path: "/groups",
    component: () => import("/codebuild/output/src3345967594/src/pages/groups/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src3345967594/src/pages/index.vue")
  },
  {
    name: "join",
    path: "/join",
    component: () => import("/codebuild/output/src3345967594/src/pages/join.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/codebuild/output/src3345967594/src/pages/login.vue")
  },
  {
    name: "membership-confirmation",
    path: "/membership/confirmation",
    component: () => import("/codebuild/output/src3345967594/src/pages/membership/confirmation.vue")
  },
  {
    name: "membership",
    path: "/membership",
    meta: indexHkHOp0pkdfMeta || {},
    component: () => import("/codebuild/output/src3345967594/src/pages/membership/index.vue")
  },
  {
    name: "news-slug",
    path: "/news/:slug()",
    component: () => import("/codebuild/output/src3345967594/src/pages/news/[slug].vue")
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/codebuild/output/src3345967594/src/pages/news/index.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("/codebuild/output/src3345967594/src/pages/reset-password.vue")
  },
  {
    name: "setup-2fa",
    path: "/setup-2fa",
    meta: setup_452facTO1Ff16KjMeta || {},
    component: () => import("/codebuild/output/src3345967594/src/pages/setup-2fa.vue")
  },
  {
    name: "shop",
    path: "/shop",
    component: () => import("/codebuild/output/src3345967594/src/pages/shop.vue")
  }
]